import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { interval, Observable, of, Subscription } from 'rxjs';
import { flatMap, map, mapTo} from 'rxjs/Operators';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private _trialEndsAt;
  private _diff: number;
  private _days: number;
  private _hours: number;
  private _minutes: number;
  private _seconds: number;
  private future: Date;
    private futureString: string;
    private counter$: Observable<number>;
    private subscription: Subscription;
    private message: string;
    get Dates() {
      return "2019-06-29";
    }

  constructor(elm: ElementRef) { }

  ngOnInit() {
    // this.future = new Date(this.Dates);
    //     this.counter$ = interval(1000).pipe(map((x) => {
    //        return Math.floor((this.future.getTime() - new Date().getTime()) / 1000);
    //     }));

    //     this.subscription = this.counter$.subscribe((x) =>  {
    //       this._days = this.getDays(x);
    //       this._hours = this.getHours(x);
    //       this._minutes = this.getMinutes(x);
    //       this._seconds = this.getSeconds(x);
    //     });
  }

//   ngOnDestroy(){
//     this.subscription.unsubscribe();
//   }


//  getDays(t) {
//   var days;
//   days = Math.floor(t / 86400);
//   return days;
// }

// getHours(t){
//   var hours;
//   t -= this._days * 86400;
//   hours = Math.floor(t / 3600) % 24;
//   return hours;
// }

// getMinutes(t){
//     var minutes;
//     t -= this._hours * 3600;
//     minutes = Math.floor(t / 60) % 60;
//      return minutes;
// }

// getSeconds(t){
//   var seconds;
//   t -= this._minutes * 60;
//   seconds = t % 60;
//   return seconds;
// }

get Banner1() {
  return "gs://snehahastha-60893.appspot.com/banner.jpg";
}
get Banner2() {
  return "gs://snehahastha-60893.appspot.com/banner1.jpg";
}

Gallery1: any ="gs://snehahastha-60893.appspot.com/gallary1.jpg";

Gallary2: any = "gs://snehahastha-60893.appspot.com/gallary2.jpg";

Gallary3: any = "gs://snehahastha-60893.appspot.com/gallary3.jpg";

 Gallary4: any = "gs://snehahastha-60893.appspot.com/gallary4.jpg";;

 Gallary5: any = "gs://snehahastha-60893.appspot.com/gallary5.jpg";

 Gallary6: any ="gs://snehahastha-60893.appspot.com/gallary6.jpg";

Gallary7: any ="gs://snehahastha-60893.appspot.com/gallary3.jpg";


}
