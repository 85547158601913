import { Component, OnInit, ElementRef, OnDestroy, Input, OnChanges } from '@angular/core';
import { interval, Observable, of, Subscription } from 'rxjs';
import { flatMap, map, mapTo} from 'rxjs/Operators';
import { ImageServiceService } from './image-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, OnChanges {
  images: any[];
  @Input() filterBy?: string = 'all'
  allImages: any[] = [];

private _trialEndsAt;
  private _diff: number;
  private _days: number;
  private _hours: number;
  private _minutes: number;
  private _seconds: number;
  private future: Date;
    private futureString: string;
    private counter$: Observable<number>;
    private subscription: Subscription;
    private message: string;
    get Dates() {
      return "2020-01-26";
    }

  public registrationLink : string = "http://goo.gl/forms/GzfaMXolOu";
  public details :  string = "https://i.ibb.co/qdchSB6/IMG-20191031-WA0055.jpg";

  constructor(elm: ElementRef, private imageService: ImageServiceService ) {
      this.allImages = this.imageService.getImages();
  }

  ngOnInit() {
       this.future = new Date(this.Dates);
         this.counter$ = interval(1000).pipe(map((x) => {
            return Math.floor((this.future.getTime() - new Date().getTime()) / 1000);
         }));

         this.subscription = this.counter$.subscribe((x) =>  {
           this._days = this.getDays(x);
           this._hours = this.getHours(x);
           this._minutes = this.getMinutes(x);
           this._seconds = this.getSeconds(x);
         });
  }



   ngOnDestroy(){
     this.subscription.unsubscribe();
   }

   getDays(t) {
  var days;
  days = Math.floor(t / 86400);
  return days;
}

getHours(t) {
  var hours;
  t -= this._days * 86400;
  hours = Math.floor(t / 3600) % 24;
  return hours;
}

getMinutes(t){
    var minutes;
    t -= this._hours * 3600;
    minutes = Math.floor(t / 60) % 60;
     return minutes;
}

getSeconds(t)  {
  var seconds;
  t -= this._minutes * 60;
  seconds = t % 60;
  return seconds;
}

ngOnChanges() {
  this.allImages = this.imageService.getImages();
}

}
