import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageServiceService {

  allImages = [];
  getImages() {
        return this.allImages = Imagesdelatils.slice(0);
    }
    getImage(id: number) {
        return Imagesdelatils.slice(0).find(images => images.id === id)
    }

}
const Imagesdelatils = [
  { "id": 1, "brand": "shivamogga", "url": "assets/images/01.jpg" },
  { "id": 2, "brand": "shivamogga", "url": "assets/images/02.jpg" },
  { "id": 1, "brand": "shivamogga", "url": "assets/images/03.jpg" },
  { "id": 2, "brand": "shivamogga", "url": "assets/images/04.jpg" },
  { "id": 1, "brand": "chamrajanagara", "url": "assets/images/chamarajnagara.jpg" },
  { "id": 2, "brand": "chamrajanagara", "url": "assets/images/chamarajnagara1.jpg" },
  { "id": 5, "brand": "mandya", "url": "assets/images/mandya.jpg" },
    { "id": 6, "brand": "mandya", "url": "assets/images/mandya2.jpg" },
    { "id": 5, "brand": "mandya", "url": "assets/images/mandya3.jpg" },
    { "id": 6, "brand": "mandya", "url": "assets/images/mandya4.jpg" },
    { 'id': 9, 'brand': 'mangaluru', 'url': 'assets/images/mangaluru2.jpg' },
   { 'id': 10, 'brand': 'mangaluru', 'url': 'assets/images/mangaluru.jpg' },
   { 'id': 14, 'brand': 'workshop', 'url': 'assets/images/workshop.jpg' },
   { 'id': 15, 'brand': 'workshop', 'url': 'assets/images/workshop1.jpg' },
]
