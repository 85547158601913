import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterimages'
})
export class FilterimagesPipe implements PipeTransform {
  transform(items: any[], brands: string): any {
    if(brands === 'all')
    { return items } else
    return items.filter(item =>{
    return item.brand === brands;
  });
  }
}
